import React, { useEffect, useState } from 'react';
import { Grid, Typography, Stepper, Step, StepLabel, Box, TextField, Button } from '@mui/material';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ActionButtons from './ActionButtons';
import UploadComponent from './UploadComponent';
import useProjectTitleValidation from '../../../hooks/useProjectTitleValidation';
import Header from '../../Header';
import { db } from '../../../firebase';

import './styles.css';
import { uploadFile } from '../../../utils/storageHelpers';
import LinearProgressWithLabel from '../LinerProgressWithLabel';
import { getFileNameWithSuffix } from '../../../utils/utils';
import { extractToken } from '../../../utils/helpers';

const AddProject = () => {
	const [activeStep, setActiveStep] = useState(0);
	const [csvFile, setCSVFile] = useState();
	const [images, setImages] = useState([]);
	const [uploadComplete, setUploadComplete] = useState(false);
	const [csvUploadProgress, setCsvUploadProgress] = useState(0);
	const [imageUploadCount, setImageUploadCount] = useState(0);
	const [totalImages, setTotalImages] = useState(0);
	const [projectName, projectNameInvalid, validateProjectTitle] = useProjectTitleValidation('');
	const steps = ['Project Title', 'Select CSV', 'Select Images'];

	const { organizationId, directoryId } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		const handleBeforeUnload = (e) => {
			if (csvUploadProgress > 0 || imageUploadCount > 0) {
				e.preventDefault();
				e.returnValue = 'Project is uploading are you sure you want to leave?';
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [csvUploadProgress, imageUploadCount]);

	const handleNext = () => {
		if (activeStep !== 2) {
			if (activeStep === 0 && !projectName) {
				validateProjectTitle('');
				return;
			}
			if (activeStep === 1 && !csvFile) {
				return;
			}
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		} else if (activeStep === 2 && images.length !== 0) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
			addProject();
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const onProjectTitleChange = (e) => {
		validateProjectTitle(e.target.value);
	};

	const uploadFiles = async (projectId) => {
		const [csvUrl, images] = await Promise.all([uploadCsvFile(projectId), uploadImages(projectId)]);

		updateDownloadURLs(projectId, images, csvUrl);
	};

	const uploadCsvFile = async (projectId) => {
		if (!csvFile) return;

		const path = '';
		try {
			return uploadFile(
				organizationId,
				projectId,
				projectName,
				csvFile,
				path,
				(progress) => setCsvUploadProgress(progress),
				'reference.csv'
			);
		} catch (e) {
			console.error('Error uploading CSV file: ', e);
		}
	};

	const uploadImages = async (projectId) => {
		if (!images || images.length === 0) return [];

		setTotalImages(images.length);
		const sortedImages = images.sort((a, b) => {
			const nameA = a.name.toUpperCase();
			const nameB = b.name.toUpperCase();
			return nameA.localeCompare(nameB);
		});

		const maxConcurrentUploads = 250;
		let activeUploads = 0;
		let currentIndex = 0;
		let uploadResults = new Array(sortedImages.length).fill(null);

		const enqueueUpload = async () => {
			if (currentIndex >= sortedImages.length) {
				if (activeUploads === 0) {
					// All uploads are complete
					return uploadResults;
				}
				// Wait for active uploads to finish
				return;
			}

			const index = currentIndex;
			const file = sortedImages[currentIndex++];
			activeUploads++;

			try {
				const downloadImgURL = await uploadFile(
					organizationId,
					projectId,
					projectName,
					file,
					'images',
					() => {}
				);
				setImageUploadCount((prevCount) => prevCount + 1);

				const token = extractToken(downloadImgURL);

				uploadResults[index] = {
					token,
					fileName: getFileNameWithSuffix(file.name),
				};
			} catch (error) {
				console.error(`Error uploading file: ${file.name}`, error);
			}

			activeUploads--;
			enqueueUpload();
		};

		// Start initial batch of uploads
		for (let i = 0; i < Math.min(maxConcurrentUploads, sortedImages.length); i++) {
			enqueueUpload();
		}

		// Wait for all uploads to complete
		await new Promise((resolve) => {
			const checkCompletion = setInterval(() => {
				if (currentIndex === sortedImages.length && activeUploads === 0) {
					clearInterval(checkCompletion);
					resolve();
				}
			}, 100);
		});

		return uploadResults;
	};

	const addProject = async () => {
		const projectPayload = {
			name: projectName,
			creationDate: new Date(),
			images: [],
		};

		try {
			const projectsCollectionRef = collection(
				db,
				'organizations',
				organizationId,
				'directories',
				directoryId,
				'projects'
			);
			const addedProjectRef = await addDoc(projectsCollectionRef, projectPayload);

			uploadFiles(addedProjectRef.id);
		} catch (error) {
			console.error('Error adding project: ', error);
		}
	};

	const updateDownloadURLs = async (projectId, images, csvURL) => {
		const projectRef = doc(db, 'organizations', organizationId, 'directories', directoryId, 'projects', projectId);
		try {
			await updateDoc(projectRef, {
				images: images,
				csvURL: csvURL,
			});
			setUploadComplete(true);
		} catch (error) {
			console.error('Error updating project images: ', error);
		}
	};

	const onBackClick = () => {
		navigate(`/admin/organizations/${organizationId}/directories/${directoryId}/projects`);
	};

	return (
		<Grid container className="add-project__admin-container" direction="column">
			<Header />
			<div style={{ maxWidth: '80%' }}>
				<Typography variant="h4" style={{ margin: '20px 0' }}>
					Add a new project
				</Typography>
				<ArrowBackIcon onClick={onBackClick} className="projects__back-button" />
				<Stepper activeStep={activeStep} orientation="vertical">
					{steps.map((label, index) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
							<Box sx={{ margin: '20px 0' }}>
								{index === 0 && (
									<TextField
										error={projectNameInvalid}
										id="input-component"
										fullWidth
										margin="normal"
										placeholder="Project Title"
										onChange={onProjectTitleChange}
										value={projectName}
										style={{
											marginTop: 0,
										}}
										disabled={activeStep !== 0}
										helperText={projectNameInvalid ? 'Please enter a title' : ''}
									/>
								)}
								{index > 0 && (
									<UploadComponent
										step={index}
										activeStep={activeStep}
										csvFile={csvFile}
										setCSVFile={setCSVFile}
										images={images}
										setImages={setImages}
									/>
								)}
								{activeStep === index && (
									<ActionButtons
										onBackClick={handleBack}
										onNextClick={handleNext}
										backButtonDisabled={index === 0}
										nextButtonDisabled={
											index !== 0 && (index === 1 ? !csvFile : images.length === 0)
										}
										step={activeStep}
									/>
								)}
							</Box>
						</Step>
					))}
					<Step>
						<StepLabel>Upload Files</StepLabel>
					</Step>
				</Stepper>
				{activeStep === 3 && (
					<div>
						{<p>Uploading CSV</p>}
						<LinearProgressWithLabel value={csvUploadProgress} />
						<p>
							Image Upload Progress: {imageUploadCount}/{totalImages}
						</p>
						<LinearProgressWithLabel value={Math.floor((imageUploadCount / totalImages) * 100) || 0} />
						<p>
							{totalImages !== 0 && imageUploadCount === totalImages ? 'Project Added Successfully!' : ''}
						</p>
					</div>
				)}
				{uploadComplete && (
					<div style={{ marginTop: 20 }}>
						<Button variant="contained" style={{ color: '#fff' }} onClick={onBackClick}>
							Back to Projects
						</Button>
					</div>
				)}
			</div>
		</Grid>
	);
};

export default AddProject;
