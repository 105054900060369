import React, { useState } from 'react';
import { Box, CircularProgress, Grid } from '@mui/material';
import FolderIconOpen from '@mui/icons-material/FolderOpen';
import FolderIconClose from '@mui/icons-material/Folder';
import VideoIcon from '@mui/icons-material/OndemandVideo';
import { getDocs, collection, query } from 'firebase/firestore';

import { db } from '../../../../firebase';

import './styles.css';

function SideBarDirectory({ directory, onProjectClick, organizationId }) {
	const [showChildren, setShowChildren] = useState(false);
	const [projects, setProjects] = useState([]);
	const [loading, setLoading] = useState(false);

	const fetchProjects = async () => {
		setLoading(true);
		const projectsRef = collection(db, 'organizations', organizationId, 'directories', directory.id, 'projects');

		const q = query(projectsRef);
		const querySnapshot = await getDocs(q);

		const projects = querySnapshot.docs.map((doc) => ({
			id: doc.id,
			name: doc.data().name,
			creationDate: doc.data().creationDate,
			images: doc.data().images,
			csvURL: doc.data().csvURL,
		}));

		setLoading(false);
		setProjects(projects);
	};

	const onDirectoryClick = () => {
		setShowChildren(!showChildren);
		fetchProjects();
	};

	return (
		<div className="sidebar-directory__directory">
			<Grid container className="sidebar-directory__directory-name-container" onClick={onDirectoryClick}>
				<Grid>
					{showChildren ? (
						<FolderIconOpen className="sidebar-directory__folder-icon-side-bar" />
					) : (
						<FolderIconClose className="sidebar-directory__folder-icon-side-bar" />
					)}
				</Grid>
				<Grid>
					<span className="sidebar-directory__project-list-item">{directory.name}</span>
				</Grid>
			</Grid>
			{showChildren && (
				<Grid className="sidebar-directory__directory-children">
					{loading && projects.length === 0 ? (
						<Box display="flex" alignItems="center">
							<CircularProgress color="alternateSecondary" size={20} sx={{ marginLeft: 4 }} />
						</Box>
					) : (
						projects.map((project) => (
							<Grid
								key={project.id}
								container
								className="sidebar-directory__directory-name-container"
								onClick={() => onProjectClick(project)}
							>
								<Grid>
									<VideoIcon className="sidebar-directory__side-bar-video-icon" />
								</Grid>
								<Grid>
									<li className={'sidebar-directory__project-list-item'}>{project.name}</li>
								</Grid>
							</Grid>
						))
					)}
				</Grid>
			)}
		</div>
	);
}

export default SideBarDirectory;
