import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './views/Home';
import Login from './views/Login';
import PrivateRoute from './PrivateRoute';
import Projects from './views/Admin/Projects';
import NewProject from './views/Admin/NewProject';
import Organizations from './views/Admin/Organizations';
import Directories from './views/Admin/Directories';

const AppRoutes = () => {
	return (
		<BrowserRouter>
			<div className="page-content">
				<Routes>
					<Route path="/login" element={<Login />} />
					<Route element={<PrivateRoute />}>
						<Route path="/" element={<Home />} />
						<Route path="/admin/organizations" element={<Organizations />} />
						<Route path="/admin/organizations/:organizationId/directories" element={<Directories />} />
						<Route
							path="/admin/organizations/:organizationId/directories/:directoryId/projects"
							element={<Projects />}
						/>
						<Route
							path="/admin/organizations/:organizationId/directories/:directoryId/new-project"
							element={<NewProject />}
						/>
					</Route>
				</Routes>
			</div>
		</BrowserRouter>
	);
};

export default AppRoutes;
