import React, { useState } from 'react';
import BaseFormComponent from './BaseAuthForm';
import { TextField } from '@mui/material';
import { confirmPasswordReset, signInWithEmailAndPassword, verifyPasswordResetCode } from 'firebase/auth';

import { auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';

function NewPasswordForm({ oobCode }) {
	const [password, setPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const navigate = useNavigate();

	const resetPassword = async () => {
		try {
			const email = await verifyPasswordResetCode(auth, oobCode);
			await confirmPasswordReset(auth, oobCode, password);
			await signInWithEmailAndPassword(auth, email, password);
			navigate('/');
		} catch (error) {
			handleAuthErrors(error);
		}
	};

	const handleAuthErrors = (error) => {
		const errorMap = {
			'auth/weak-password': 'Password should be at least 6 characters',
			'auth/expired-action-code': 'This reset code has expired. Please request a new one.',
			'auth/invalid-action-code': 'This reset code is invalid. Please check the link or request a new one.',
			default: 'An error occurred while updating the password.',
		};
		setErrorMessage(errorMap[error.code] || errorMap['default']);
	};

	return (
		<BaseFormComponent title="Set New Password" onSubmit={resetPassword}>
			<TextField
				label="New Password"
				variant="outlined"
				type="password"
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				margin="normal"
				error={errorMessage !== ''}
				helperText={errorMessage}
			/>
		</BaseFormComponent>
	);
}

export default NewPasswordForm;
