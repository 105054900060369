import React from 'react';
import { Box, Typography, Button } from '@mui/material';

function BaseFormComponent({ title, children, onSubmit }) {
	const submitForm = (e) => {
		e.preventDefault();
		onSubmit(e);
	};
	return (
		<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
			<form onSubmit={submitForm} style={{ display: 'flex', flexDirection: 'column' }}>
				<Typography variant="h4" gutterBottom>
					{title}
				</Typography>
				{children}
				<Button variant="contained" color="primary" type="submit" sx={{ mt: 2 }}>
					{title}
				</Button>
			</form>
		</Box>
	);
}

export default BaseFormComponent;
