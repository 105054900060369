import React from 'react';
import BaseFormComponent from './BaseAuthForm';
import { TextField, Typography } from '@mui/material';

function ResetPasswordForm({ email, setEmail, errorMessage, onSubmit }) {
	return (
		<BaseFormComponent title="Reset Password" onSubmit={onSubmit}>
			<Typography sx={{ color: 'grey' }}>Enter your email to get a password reset link</Typography>
			<TextField
				label="Email"
				variant="outlined"
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				type="email"
				margin="normal"
				error={errorMessage !== ''}
				helperText={errorMessage}
			/>
		</BaseFormComponent>
	);
}

export default ResetPasswordForm;
