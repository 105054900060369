import React, { useState } from 'react';
import FolderIcon from '@mui/icons-material/Folder';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';

import './styles.css';
import { useNavigate, useParams } from 'react-router-dom';

const DirectoryCard = ({ directory, onDelete, inAddMode = false, onNameChange, onSubmit }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const navigate = useNavigate();
	const { organizationId } = useParams()

	const handleClick = (event) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (e) => {
		setAnchorEl(null);
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			onSubmit && onSubmit();
		}
	};

	const handleDirectoryClick = (directory) => {
		navigate(`/admin/organizations/${organizationId}/directories/${directory.id}/projects`);
	}

	return (
		<div className="directory-card" onClick={inAddMode ? null : () => handleDirectoryClick(directory)}>
			<div className="directory-card__icon">
				<FolderIcon />
			</div>
			{inAddMode ? (
				<Input
					autoFocus
					size="small"
					variant="outlined"
					placeholder="New Folder Name"
					onChange={(e) => onNameChange(e.target.value)}
					onKeyDown={handleKeyDown}
					onBlur={onSubmit}
				/>
			) : (
				<div className="directory-card__name">{directory.name}</div>
			)}
			{!inAddMode && (
				<div className="directory-card__menu">
					<IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
						<MoreVertIcon />
					</IconButton>
					<Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
						<MenuItem
							onClick={(e) => {
								e.stopPropagation();
								onDelete(directory);
								handleClose();
							}}
						>
							Delete
						</MenuItem>
					</Menu>
				</div>
			)}
		</div>
	);
};

export default DirectoryCard;
