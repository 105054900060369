import React, { useState } from 'react';
import { Button, Divider, FormControl, Grid, MenuItem, Select } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useNavigate } from 'react-router-dom';

import { auth } from '../../../firebase';
import './styles.css';
import { ROLE_PRIVILEGES } from '../../../common/consts';
import SideBarDirectory from './SidebarDirectory';

const SideBar = ({ loadProject, directories, organizationId, user, organizations, switchOrganization }) => {
	const [open, setOpen] = useState(false);

	const navigate = useNavigate();

	const toggleDrawer = () => {
		setOpen(!open);
	};

	const logout = () => {
		auth.signOut();
	};

	const onProjectClick = (project) => {
		loadProject(project);
		toggleDrawer();
	};

	return (
		<section style={{ height: '100%' }}>
			<SwipeableDrawer
				open={open}
				onClose={toggleDrawer}
				onOpen={toggleDrawer}
				PaperProps={{
					style: { backgroundColor: '#164172' },
				}}
			>
				<Grid
					className="sidebar__side-bar"
					style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
				>
					{user?.privilegeLevel >= ROLE_PRIVILEGES.ADMIN && (
						<Grid style={{ textAlign: 'right', padding: '10px 20px' }}>
							<Button
								onClick={() => navigate('/admin/organizations')}
								variant="contained"
								className="sidebar__admin-button"
							>
								ADMIN
							</Button>
						</Grid>
					)}
					<h2 className="sidebar__header">
						{organizations.length > 1 ? (
							<FormControl fullWidth size="small">
								<Select
									labelId="org-select-label"
									value={organizationId}
									onChange={(e) => switchOrganization(e.target.value)}
									color="alternateSecondary"
									sx={{
										color: '#ccc',
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: '#ccc',
										},
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: '#ccc',
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: '#ccc',
										},
										'& .MuiSelect-icon': {
											color: '#ccc',
										},
									}}
									MenuProps={{
										PaperProps: {
											style: {
												backgroundColor: '#ccc',
												color: 'black',
											},
										},
									}}
									SelectProps={{
										IconComponent: (props) => <props.className sx={{ color: '#ccc' }} />,
									}}
								>
									{organizations.map((org) => (
										<MenuItem key={org.id} value={org.id}>
											{org.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						) : organizations.length === 1 ? (
							<span>{organizations[0].name}</span>
						) : (
							'No Organizations'
						)}
					</h2>
					<div style={{ padding: '0px 20px' }}>
						<Divider className="sidebar__divider" />
					</div>
					<section className="sidebar__my-projects-container" style={{ overflowY: 'auto' }}>
						<ul className="sidebar__project-list">
							{directories.map((directory) => (
								<SideBarDirectory
									key={directory.id}
									onProjectClick={onProjectClick}
									directory={directory}
									organizationId={organizationId}
								/>
							))}
						</ul>
					</section>
					<Grid style={{ textAlign: 'center', paddingBottom: 30, paddingTop: 30 }}>
						<Button onClick={logout} variant="contained" className="sidebar__logout-button">
							LOG OUT
						</Button>
					</Grid>
				</Grid>
			</SwipeableDrawer>
			<Grid className="sidebar__closed-side-bar">
				<IconButton onClick={toggleDrawer} className="sidebar__menu-icon">
					<MenuIcon sx={{ color: '#fff' }} />
				</IconButton>
			</Grid>
		</section>
	);
};

export default SideBar;
