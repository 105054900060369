const prodConfig = {
	apiKey: 'AIzaSyDE5Cy-MCjzp70l91wTm6jp3KIIc3OMsGY',
	authDomain: 'inspections-web-2aaa8.firebaseapp.com',
	projectId: 'inspections-web-2aaa8',
	storageBucket: 'inspections-web-2aaa8.appspot.com',
	messagingSenderId: '99293116604',
	appId: '1:99293116604:web:f51b8e7693c35b46a578b9',
	measurementId: 'G-2H3RP54XR9',
	defaultRegion: 'asia-south1',
};

const testConfig = {
	apiKey: 'AIzaSyD2xLplx3Po1P8L-ntHqrNeWpwdgwgXcIs',
	authDomain: 'inspections-web-testing.firebaseapp.com',
	databaseURL: 'https://inspections-web-testing.firebaseio.com',
	projectId: 'inspections-web-testing',
	storageBucket: 'inspections-web-testing.appspot.com',
	messagingSenderId: '625800916063',
	appId: '1:625800916063:web:e49c4e748afeb58089ffea',
	defaultRegion: 'asia-south1',
};

let firebaseConfig = testConfig;
if (process.env.REACT_APP_FIREBASE_ENV === 'production') {
	firebaseConfig = prodConfig;
}

export default firebaseConfig;
