import React, { useState } from 'react';
import { setDoc, collection, doc } from 'firebase/firestore';
import { notEmpty } from '../validators';
import { db } from '../../../firebase';

import OrganizationCard from '../OrganizationCard';

const NewOrganization = ({ fetchOrganizations, hideNewOrganizationInput }) => {
	const [organizationName, setOrganizationName] = useState('');
	const [isNameValid, setIsNameValid] = useState(true);

	const handleNameChange = (name) => {
		const isOrganizationNameValid = notEmpty(name);
		setOrganizationName(name);
		setIsNameValid(isOrganizationNameValid);
	};

	const onAddClick = async () => {
		const effectiveOrganizationName =
			!isNameValid || organizationName.trim() === '' ? 'Untitled' : organizationName.trim();

		const organization = {
			name: effectiveOrganizationName,
			users: [],
			creationDate: new Date(),
		};

		await addOrganization(organization);
		hideNewOrganizationInput();
	};

	const addOrganization = async (organization) => {
		try {
			await setDoc(doc(collection(db, 'organizations')), organization);
			fetchOrganizations();
		} catch (error) {
			console.error('Error adding document: ', error);
		}
	};

	return (
		<OrganizationCard
			organization={{ name: organizationName }}
			inAddMode={true}
			onNameChange={handleNameChange}
			onSubmit={onAddClick}
		/>
	);
};

export default NewOrganization;
