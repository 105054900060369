import { useState } from "react";

const useProjectTitleValidation = (initialValue) => {
	const [projectName, setProjectName] = useState(initialValue);
	const [projectNameInvalid, setProjectNameInvalid] = useState(false);

	const validateProjectTitle = (title) => {
		setProjectName(title);
		setProjectNameInvalid(title === "");
	};

	return [projectName, projectNameInvalid, validateProjectTitle];
};

export default useProjectTitleValidation;
