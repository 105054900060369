import React, { useCallback } from 'react';
import { Backdrop, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { throttle } from 'lodash';

const AlertDialog = ({ open, handleClose, onYesClick, disableButtons, itemToDelete }) => {
	const debouncedOnYesClick = useCallback(throttle(onYesClick, 300), [itemToDelete]);
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					sx: {
						backgroundColor: 'rgba(0, 0, 0, 0.5) !important',
					},
				},
			}}
		>
			<DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description" sx={{ width: 300 }}>
					You can't undo this action
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<LoadingButton
					onClick={debouncedOnYesClick}
					disabled={disableButtons}
					color="secondary"
					loading={disableButtons}
				>
					<span>Yes</span>
				</LoadingButton>
				<Button onClick={handleClose} disabled={disableButtons} color="secondary" autoFocus>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AlertDialog;
