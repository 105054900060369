import React from 'react';
import { Grid } from '@mui/material'

import InspectionsHeading from "../components/InspectionsHeading";
import alwarqaLogo from "../../assets/alwarqaLogo.png";
import "./styles.css";

const Header = (props) => {
    return (<Grid container>
        <Grid item xs={2}>
            <InspectionsHeading />
        </Grid>
        <Grid item xs={7} />
        <Grid item xs={3} sx={{ textAlign: 'right' }}>
            <a href="http://www.alwarqasurvey.com/" target="_blank" rel="noreferrer">
                <img
                    src={alwarqaLogo}
                    alt="alwarqa logo"
                    className="header__home-alwarqa-logo"
                />
            </a>
        </Grid>
    </Grid>)
}

export default Header;