export const notEmpty = (string) => {
    return string !== ''
}

export const isEmailValid = (email) => {
    return !email.includes(" ") && email.includes("@");
}

export const isVideo = (givenURL) => {
    return givenURL.includes("https://") && !givenURL.includes(" ") && givenURL.includes(".mp4")
}

export const isCSV = (givenURL) => {
    return givenURL.includes("https://") && !givenURL.includes(" ") && givenURL.includes(".csv");
}