import React from "react";
import { Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Article } from "@mui/icons-material";

const FileDisplay = ({ name, onRemove, isDisabled }) => {
	return (
		<div
			style={{
				display: "flex",
				marginTop: "20px",
				pointerEvents: isDisabled ? "none" : "auto",
				opacity: isDisabled ? 0.5 : 1,
			}}
		>
			<div
				style={{
					backgroundColor: "#fbfaf8",
					display: "flex",
					alignItems: "center",
					padding: "10px",
					border: "1px solid #e0e0e0",
					borderRadius: 8,
				}}
			>
				<Article />
				<Typography
					variant="subtitle1"
					style={{
						marginLeft: 10,
						opacity: isDisabled ? 0.5 : 1,
					}}
				>
					{name}
				</Typography>
				<IconButton
					onClick={onRemove}
					size="small"
					style={{
						marginLeft: 1,
						opacity: isDisabled ? 0.5 : 1,
					}}
				>
					<CloseIcon fontSize="small" />
				</IconButton>
			</div>
		</div>
	);
};

export default FileDisplay;
