import React, { useEffect, useState } from 'react';

import UserAdminModalBase from '../UserAdminModalBase';
import { callFirebaseFunction } from '../../../utils/helpers';

function UsersModal({ open, toggleModal, organizationId }) {
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);

	const fetchUsers = async () => {
		setLoading(true);
		try {
			const fetchedUsers = await callFirebaseFunction('getUsersForOrganization', { organizationId });
			setUsers(fetchedUsers);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (users.length === 0) {
			fetchUsers();
		}
	}, []);

	const deleteUser = async (user) => {
		try {
			setLoading(true);
			await callFirebaseFunction('removeUserFromOrganization', { email: user.email, organizationId });
			fetchUsers();
		} catch {
			setLoading(false);
		}
	};

	const addNewUser = async (email) => {
		setLoading(true);
		try {
			await callFirebaseFunction('addUserToOrganization', { email, organizationId });
			fetchUsers();
		} catch {
			setLoading(false);
		}
	};

	return (
		<UserAdminModalBase
			users={users}
			open={open}
			toggleModal={toggleModal}
			updateOrDeleteUser={deleteUser}
			type="users"
			addNewUser={addNewUser}
			loading={loading}
		/>
	);
}

export default UsersModal;
