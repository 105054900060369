import { Button, Typography } from '@mui/material';

function PasswordResetSent({ email, onBack }) {
	return (
		<>
			<Typography variant="h5">Password Reset Link Sent!</Typography>
			<Typography sx={{ marginTop: 5 }}>
				Please check your inbox for a password reset link. We've sent it to {email}. Follow the link to reset
				your password. If you don't receive the email soon, check your spam folder.
			</Typography>
			<Button variant="contained" sx={{ marginTop: 5 }} onClick={onBack}>
				Back to Login
			</Button>
		</>
	);
}

export default PasswordResetSent;
