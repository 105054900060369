import React from 'react';
import BaseFormComponent from './BaseAuthForm';
import { TextField, Typography } from '@mui/material';

function LoginForm({ email, setEmail, password, setPassword, errorMessage, handleForgotPasswordClick, handleLogin }) {
	return (
		<BaseFormComponent title="Log In" onSubmit={handleLogin}>
			<TextField
				label="Email"
				variant="outlined"
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				type="email"
				margin="normal"
				error={errorMessage !== ''}
			/>
			<TextField
				label="Password"
				variant="outlined"
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				type="password"
				margin="normal"
				error={errorMessage !== ''}
				helperText={errorMessage}
			/>
			<Typography
				variant="body2"
				color="primary"
				sx={{ fontSize: 12, textAlign: 'right', cursor: 'pointer' }}
				onClick={handleForgotPasswordClick}
			>
				Forgot Password?
			</Typography>
		</BaseFormComponent>
	);
}

export default LoginForm;
