import React, { useState } from 'react';
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography, Avatar, Input } from '@mui/material';
import OrganizationIcon from '@mui/icons-material/Business';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import DummyOrg from '../../../assets/dummyOrg.webp';
import './styles.css';
import { useNavigate } from 'react-router-dom';

const OrganizationCard = ({ organization, inAddMode = false, onNameChange, onSubmit, onDelete }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const navigate = useNavigate();

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			onSubmit && onSubmit();
		}
	};

	const onOrganizationClick = () => {
		navigate(`/admin/organizations/${organization.id}/directories`);
	};
	const handleClick = (event) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (e) => {
		e.stopPropagation()
		setAnchorEl(null);
	};
	return (
		<Card className="organization-card__root" onClick={onOrganizationClick}>
			<CardActionArea>
				<CardMedia className="organization-card__media" image={DummyOrg} />
				<CardContent style={{ padding: 10 }}>
					<Box display="flex" alignItems="center" justifyContent="space-between">
						<Box display="flex" alignItems="center">
							<Avatar style={{ marginRight: 10 }}>
								<OrganizationIcon />
							</Avatar>
							{inAddMode ? (
								<Input
									autoFocus
									size="small"
									variant="outlined"
									placeholder="New Folder Name"
									onChange={(e) => onNameChange(e.target.value)}
									onKeyDown={handleKeyDown}
									onBlur={onSubmit}
								/>
							) : (
								<Typography gutterBottom className="organization-card__name">
									{organization.name}
								</Typography>
							)}
						</Box>
						<IconButton
							aria-label="more"
							aria-controls="long-menu"
							aria-haspopup="true"
							onClick={handleClick}
						>
							<MoreVertIcon />
						</IconButton>
						<Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
						<MenuItem
							onClick={(e) => {
								e.stopPropagation();
								onDelete(organization);
								handleClose(e);
							}}
						>
							Delete
						</MenuItem>
					</Menu>
					</Box>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};

export default OrganizationCard;
