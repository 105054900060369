import { ref, uploadBytesResumable, getDownloadURL, listAll, deleteObject } from 'firebase/storage';
import { storage } from '../firebase';
import { getFileNameWithSuffix, getFolderPath, getSuffix } from './utils';

const uploadFile = (organizationId, projectId, projectName, file, path, onProgress, fileName = file.name) => {
	return new Promise((resolve, reject) => {
		const folderPath = getFolderPath(organizationId, projectId, projectName);
		const folderRef = ref(storage, `${folderPath}/${path}/${fileName}`);
		const uploadTask = uploadBytesResumable(folderRef, file);

		uploadTask.on(
			'state_changed',
			(snapshot) => {
				const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				onProgress(progress);
			},
			(error) => {
				reject(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					if (fileName.endsWith('.jpg')) {
						const modifiedURL = downloadURL.replace(fileName, getFileNameWithSuffix(fileName));
						resolve(modifiedURL);
					} else {
						resolve(downloadURL);
					}
				}).catch((error) => {
                    if (error.code === 'storage/object-not-found') {
                        const modifiedFileName = adjustFileNameBasedOnKnownPattern(fileName);
                        const modifiedRef = ref(storage, `${folderPath}/${path}/${modifiedFileName}`);

                        getDownloadURL(modifiedRef).then((downloadURL) => {
                            resolve(downloadURL);
                        }).catch((error) => {
                            reject(error);
                        });
                    } else {
                        reject(error);
                    }
                });
			}
		);
	});
};

const adjustFileNameBasedOnKnownPattern = (fileName) => {
    if (fileName.endsWith('.jpg')) {
        return fileName.replace('.jpg', getSuffix());
    }
    return fileName; 
};

const deleteProjectStorage = async (directoryPath) => {
	const directoryRef = ref(storage, directoryPath);

	try {
		const list = await listAll(directoryRef);
		const fileDeletions = list.items.map((itemRef) => deleteObject(itemRef));
		const subdirDeletions = list.prefixes.map((subdirRef) => deleteProjectStorage(subdirRef.fullPath));

		await Promise.all([...fileDeletions, ...subdirDeletions]);
	} catch (error) {
		console.error('Error deleting directory: ', error);
		throw error;
	}
};

export { uploadFile, deleteProjectStorage };
