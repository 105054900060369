import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import './App.css';
import AppRoutes from './Routes';
import { AuthProvider } from './Auth';

const theme = createTheme({
	palette: {
		primary: {
			main: '#164172',
		},
		secondary: {
			main: '#231F20',
		},
		alternateSecondary: {
			main: '#CCCCCC',
		},
	},
});

function App() {
	return (
		<AuthProvider>
			<ThemeProvider theme={theme}>
				<AppRoutes />
			</ThemeProvider>
		</AuthProvider>
	);
}

export default App;
