import React, { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ImageIcon from '@mui/icons-material/Image';
import TodayIcon from '@mui/icons-material/Today';
import { Card, CardMedia, IconButton, Menu, MenuItem } from '@mui/material';
import ProjectIcon from '../../../assets/project-icon.png';

import './styles.css';

const ProjectCard = ({ project, onEdit, onDelete }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Card
			sx={{
				width: 350,
				height: 100,
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'stretch',
				justifyContent: 'space-between',
				padding: '4px',
				position : 'relative'
			}}
		>
			<div style={{ display: 'flex', alignItems: 'stretch' }}>
				<CardMedia
					component="img"
					sx={{ width: 100, height: '100%', objectFit: 'cover', borderRadius: 1 }}
					image={ProjectIcon}
					alt="Descriptive Alt Text"
				/>
				<div style={{ margin: '8px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
					<div
						className="project-card"
						style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
						onClick={() => onEdit(project)}
					>
						<div className="project-card__name">{project.name}</div>
						<div style={{ fontSize: 14, display: 'flex', alignItems: 'center' }}>
							<ImageIcon fontSize="small" style={{ display: 'block' }} />
							{project.images?.length}
						</div>
						<div style={{ fontSize: 14, display: 'flex', alignItems: 'center', marginTop: 10 }}>
							<TodayIcon fontSize="small" style={{ display: 'block' }} />
							{project.creationDate.toDate().toLocaleDateString('en-US', {
								year: 'numeric',
								month: 'short',
								day: 'numeric',
							})}
						</div>
					</div>
				</div>
			</div>
			<div className="directory-card__menu" style={{ position: 'absolute', top: 0, right: 0 }}>
				<IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
					<MoreVertIcon />
				</IconButton>
				<Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
					<MenuItem
						onClick={(e) => {
							e.stopPropagation();
							onDelete(project);
							handleClose();
						}}
					>
						Delete
					</MenuItem>
				</Menu>
			</div>
		</Card>
	);
};

export default ProjectCard;
