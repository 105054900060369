import React from 'react';
import { Link } from 'react-router-dom';

const InspectionsHeading = (props) => {
    const classes = {};
    return (<h1 className={classes?.inspectionsHeading}>
        <Link to="/" >
            Inspections
        </Link>
    </h1 >);
}

export default InspectionsHeading;