import { useEffect, useState } from 'react';
import { Grid, Button, Input, Typography, Snackbar, Alert, Box, CircularProgress } from '@mui/material';
import { getDocs, collection, deleteDoc, doc } from 'firebase/firestore';
import SupervisorAccount from '@mui/icons-material/SupervisorAccount';
import AddIcon from '@mui/icons-material/Add';

import Header from '../../Header';
import { db } from '../../../firebase';
import OrganizationCard from '../OrganizationCard';
import NewOrganization from '../NewOrganization';
import AlertDialog from '../../components/AlertDialog';

import './styles.css';
import AdminsModal from '../AdminsModal';

const Organizations = (props) => {
	const [organizations, setOrganizations] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [showNewOrganization, setShowNewOrganization] = useState(false);
	const [itemToDelete, setItemToDelete] = useState('');
	const [openAlert, setOpenAlert] = useState(false);
	const [disableButtons, setDisableButtons] = useState(false);
	const [projectsExist, setProjectsExist] = useState(false);
	const [showAdmins, setShowAdmins] = useState(false);

	const fetchOrganizations = async () => {
		const fetchedOrganizations = [];
		const querySnapshot = await getDocs(collection(db, 'organizations'));
		querySnapshot.forEach((doc) => {
			const data = doc.data();
			fetchedOrganizations.push({
				id: doc.id,
				name: data.name,
			});
		});
		setOrganizations(fetchedOrganizations);
	};

	const checkIfOrganizationsContainsDirectory = async (organization) => {
		const directoriesRef = collection(db, 'organizations', organization.id, 'directories');
		const querySnapshot = await getDocs(directoriesRef);
		return !querySnapshot.empty;
	};

	useEffect(() => {
		if (organizations.length === 0) {
			fetchOrganizations();
		}
	}, []);

	const onDeleteOrganizationClick = (organization) => {
		setItemToDelete(organization);
		setOpenAlert(true);
	};

	const deleteDirectory = async (organization) => {
		const directoryRef = doc(db, 'organizations', organization.id);
		try {
			await deleteDoc(directoryRef);
			fetchOrganizations();
			setOpenAlert(false);
			setDisableButtons(false);
		} catch (error) {
			console.error('Error deleting directory: ', error);
		}
	};

	const onAlertBoxYesClick = async () => {
		if (await checkIfOrganizationsContainsDirectory(itemToDelete)) {
			setProjectsExist(true);
		} else {
			setDisableButtons(true);
			deleteDirectory(itemToDelete);
		}
	};

	const closeAlert = () => {
		if (!disableButtons) {
			setOpenAlert(false);
		}
	};

	const handleClose = () => {
		setProjectsExist(false);
	};

	const toggleAdmins = () => {
		setShowAdmins(!showAdmins);
	};

	return organizations ? (
		<Grid className="organizations__root">
			<Header />
			<div style={{ maxWidth: '90%', paddingBottom: 30 }}>
				<Typography variant="h4" style={{ margin: '20px 0' }}>
					Organizations
				</Typography>
				<Input placeholder="Search..." onChange={(e) => setSearchQuery(e.target.value)} />
				<Button
					color="secondary"
					variant="contained"
					onClick={() => setShowNewOrganization(true)}
					startIcon={<AddIcon />}
					sx={{ marginLeft: 6 }}
				>
					Add Organization
				</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={toggleAdmins}
					startIcon={<SupervisorAccount />}
					sx={{
						borderRadius: 28,
						marginLeft: 2,
					}}
				>
					Admins
				</Button>
				<div className="organizations__container">
					{showNewOrganization && (
						<NewOrganization
							fetchOrganizations={fetchOrganizations}
							hideNewOrganizationInput={() => setShowNewOrganization(false)}
						/>
					)}
					{organizations
						.filter((organization) =>
							organization.name.toLowerCase().includes(searchQuery.toLocaleLowerCase())
						)
						.map((organization) => (
							<OrganizationCard organization={organization} onDelete={onDeleteOrganizationClick} key={organization.id}/>
						))}
				</div>
			</div>
			<AlertDialog
				open={openAlert}
				onYesClick={onAlertBoxYesClick}
				handleClose={closeAlert}
				disableButtons={disableButtons}
				itemToDelete={itemToDelete}
			/>
			<AdminsModal toggleModal={toggleAdmins} open={showAdmins} />
			<Snackbar
				open={projectsExist}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Alert onClose={handleClose} severity="error" variant="filled" sx={{ width: '100%' }}>
					Cannot delete Organization. Directories exist in this Organization.
				</Alert>
			</Snackbar>
		</Grid>
	) : (
		<Box
			sx={{
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: 'rgba(255, 255, 255, 0.8)',
				zIndex: 2,
			}}
		>
			<CircularProgress />
		</Box>
	);
};

export default Organizations;
