import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';

import './styles.css';
import { auth } from '../../firebase';
import LoginForm from './LoginForm';
import ResetPasswordForm from './ResetPasswordForm';
import NewPasswordForm from './NewPasswordForm';
import PasswordResetSent from './PasswordResetSent';

function AuthForm() {
	const [currentView, setCurrentView] = useState('login');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const oobCode = queryParams.get('oobCode');

	const handleBackToLogin = () => {
		setEmail('');
		setErrorMessage('');
		setCurrentView('Login');
	};

	const handleForgotPasswordClick = () => {
		setPassword('');
		setErrorMessage('');
		setCurrentView('reset');
	};

	const handleLogin = () => {
		signInWithEmail();
	};

	const handleSendResetEmail = async () => {
		try {
			await sendPasswordResetEmail(auth, email);
			setCurrentView('sent');
		} catch (error) {
			handleAuthErrors(error);
		}
	};

	const signInWithEmail = async () => {
		try {
			await signInWithEmailAndPassword(auth, email, password);
		} catch (error) {
			setErrorMessage("Email and password don't match");
		}
	};

	const handleAuthErrors = (error) => {
		const errorMap = {
			'auth/user-not-found': 'Email not found',
			'auth/invalid-email': 'Invalid Email Address',
			'auth/missing-email': 'Invalid Email Address',
			default: 'An error occurred.',
		};
		setErrorMessage(errorMap[error.code] || errorMap['default']);
	};

	return (
		<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
			{oobCode ? (
				<NewPasswordForm oobCode={oobCode} />
			) : currentView === 'sent' ? (
				<PasswordResetSent email={email} onBack={handleBackToLogin} />
			) : currentView === 'reset' ? (
				<ResetPasswordForm
					email={email}
					setEmail={setEmail}
					onSubmit={handleSendResetEmail}
					errorMessage={errorMessage}
				/>
			) : (
				<LoginForm
					email={email}
					setEmail={setEmail}
					password={password}
					setPassword={setPassword}
					errorMessage={errorMessage}
					handleForgotPasswordClick={handleForgotPasswordClick}
					handleLogin={handleLogin}
				/>
			)}
		</Box>
	);
}

export default AuthForm;
