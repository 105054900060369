import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import './styles.css';
import { AuthContext } from '../../Auth';
import backgroundImg from '../../assets/login-background.jpg';
import alwarqaLogo from '../../assets/alwarqaLogo.png';
import InspectionsHeading from '../components/InspectionsHeading';
import AuthForm from './AuthForm';

const Login = () => {
	const { currentUser } = useContext(AuthContext);

	if (currentUser) {
		return <Navigate to="/" replace />;
	}

	return (
		<Grid container style={{ overflow: 'hidden' }}>
			<Grid xs={4} className="login__form-container">
				<InspectionsHeading />
				<h4 className="login__tag-line">Geo referencing made easy</h4>
				<AuthForm />
			</Grid>
			<Grid xs={8} className="login__page-image">
				<img src={alwarqaLogo} alt="alwarqa logo" className="login__alwarqa-logo" />
				<img src={backgroundImg} alt="map inside a car" className="login__background-img" />
			</Grid>
		</Grid>
	);
};

export default Login;
