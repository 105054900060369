import React from "react";
import { Box, Button } from "@mui/material";

const ActionButtons = ({
	backButtonDisabled,
	nextButtonDisabled,
	onBackClick,
	onNextClick,
	step,
}) => (
	<Box sx={{ display: "flex", flexDirection: "row", margin: "14px 0px" }}>
		<Button
			color="secondary"
			disabled={backButtonDisabled}
			onClick={onBackClick}
			sx={{ mr: 1 }}
		>
			Back
		</Button>
		<Button
			onClick={onNextClick}
			disabled={nextButtonDisabled}
			variant="contained"
			style={{ color: "#fff" }}
		>
			{step === 2 ? "Submit" : "Next"}
		</Button>
	</Box>
);

export default ActionButtons;
