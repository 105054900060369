import React, { useEffect, useState } from 'react';
import 'aframe';
import { Grid, Card, IconButton, Box, CircularProgress } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import videoBackgroundDefault from '../../assets/default-video-background.jpg';
import './styles.css';
import { constructURL } from '../../utils/helpers';

const MediaDisplay = ({
	nextImage,
	prevImage,
	images,
	loading,
	currentImage,
	orgId,
	projectId,
	projectName,
	imageIndexes: { fromIndex, toIndex },
}) => {
	const [imageLoadStatus, setImageLoadStatus] = useState({});

	useEffect(() => {
		const loadStatus = images.slice(fromIndex, toIndex).reduce(
			(acc, image) => ({
				...acc,
				[image.token]: imageLoadStatus[image.token] || false,
			}),
			{}
		);
		setImageLoadStatus(loadStatus);
	}, [images, fromIndex, toIndex]);

	const handleImageLoad = (token) => {
		if (token in imageLoadStatus) {
			setImageLoadStatus((prev) => ({
				...prev,
				[token]: true,
			}));
		}
	};

	const url = currentImage && constructURL(orgId, projectId, projectName, currentImage.fileName, currentImage.token);

	const isImageLoading = !imageLoadStatus[currentImage?.token] || loading;

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (!isImageLoading) {
				if (event.key === 'ArrowLeft' && currentImage.fileName !== images[0].fileName) {
					prevImage();
				}
				if (event.key === 'ArrowRight' && currentImage.fileName !== images[images.length - 1].fileName) {
					nextImage();
				}
			}
		};
		if (images.length !== 0) {
			window.addEventListener('keydown', handleKeyDown);
		}

		return () => {
			if (images.length !== 0) {
				window.removeEventListener('keydown', handleKeyDown);
			}
		};
	}, [isImageLoading, currentImage, images, prevImage, nextImage]);

	return (
		<Grid className="video__video-container">
			{images.length !== 0 && currentImage ? (
				<Grid style={{ width: '100%', height: '100%' }}>
					<Card style={{ width: '100%', height: '100%' }}>
						{isImageLoading && (
							<Box
								display="flex"
								justifyContent="center"
								alignItems="center"
								style={{ width: '100%', height: '100%', backgroundColor: '#656565' }}
							>
								<CircularProgress size={50} />
							</Box>
						)}
						<a-scene embedded>
							<a-camera wasd-controls-enabled="false" />
							<a-assets>
								{images.slice(fromIndex, toIndex).map((image) => {
									const url = constructURL(
										orgId,
										projectId,
										projectName,
										image.fileName,
										image.token
									);

									return (
										<img
											onLoad={() => handleImageLoad(image.token)}
											id={url}
											src={url}
											crossOrigin="anonymous"
											alt="360"
											key={url}
										/>
									);
								})}
							</a-assets>
							<a-sky rotation="5 100 0" id="sky" src={`#${url}`} />
						</a-scene>
					</Card>
					<Grid container className="video__video-controls-container">
						<div>
							<IconButton
								onClick={prevImage}
								disabled={isImageLoading || currentImage.fileName === images[0].fileName}
								color="secondary"
								aria-label="previous image"
								variant="contained"
								sx={{
									color: '#fff',
									backgroundColor: '#164172',
									'&:hover': {
										backgroundColor: '#1d5696;',
									},
									...(isImageLoading && {
										backgroundColor: 'transparent',
										color: 'grey',
									}),
								}}
							>
								<ArrowForwardIosIcon sx={{ transform: 'scaleX(-1)' }} />
							</IconButton>

							<IconButton
								onClick={nextImage}
								disabled={
									isImageLoading || currentImage.fileName === images[images.length - 1].fileName
								}
								color="secondary"
								aria-label="next image"
								variant="contained"
								sx={{
									color: '#fff',
									backgroundColor: '#164172',
									'&:hover': {
										backgroundColor: '#1e5a90',
									},
									...(isImageLoading && {
										backgroundColor: 'transparent',
										color: 'grey',
									}),
									marginLeft: 2,
								}}
							>
								<ArrowForwardIosIcon />
							</IconButton>
						</div>
					</Grid>
				</Grid>
			) : (
				<div style={{ height: '100%', width: '100%' }}>
					<img
						src={videoBackgroundDefault}
						alt="blurred background with a street"
						className="video__video-background"
					/>
					<span className="video__button-container">Please Select a Project</span>
				</div>
			)}
		</Grid>
	);
};

export default MediaDisplay;
