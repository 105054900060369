import React, { useState } from 'react';
import {
	Modal,
	Box,
	List,
	ListItem,
	Typography,
	Avatar,
	Select,
	FormControl,
	MenuItem,
	Backdrop,
	TextField,
	InputAdornment,
	Button,
	CircularProgress,
} from '@mui/material';

import { formatRoleName, getRoleFromPrivilege } from '../../../utils/utils';
import { ROLE_PRIVILEGES } from '../../../common/consts';
import { auth } from '../../../firebase';

function UserAdminModalBase({ type, users, open, toggleModal, updateOrDeleteUser, addNewUser, loading }) {
	const [email, setEmail] = useState('');
	const [isValidEmail, setIsValidEmail] = useState(false);

	const validateEmail = (email) => {
		const regex = /^\S+@\S+\.\S+$/;
		return regex.test(String(email).toLowerCase());
	};

	const handleEmailChange = (event) => {
		const emailInput = event.target.value;
		setEmail(emailInput);
		setIsValidEmail(validateEmail(emailInput));
	};

	const isAdmins = type === 'admins';
	return (
		<Modal
			open={open}
			onClose={toggleModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					sx: {
						backgroundColor: 'rgba(0, 0, 0, 0.5) !important',
					},
				},
			}}
		>
			<Box
				sx={{
					position: 'absolute',
					top: '40%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: 600,
					bgcolor: 'background.paper',
					borderRadius: 2,
					boxShadow: 24,
					p: 3,
					overflow: 'hidden',
				}}
			>
				{loading && (
					<Box
						sx={{
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							backgroundColor: 'rgba(255, 255, 255, 0.8)',
							zIndex: 2,
						}}
					>
						<CircularProgress />
					</Box>
				)}
				<Typography id="modal-modal-title" variant="h5">
					{isAdmins ? 'Admins' : 'Users with Access'}
				</Typography>
				<TextField
					fullWidth
					type="email"
					label={`Add a new ${isAdmins ? 'Admin' : 'User'}`}
					variant="outlined"
					margin="normal"
					sx={{
						marginTop: 2,
						marginBottom: 2,
					}}
					placeholder="Enter an Email Address"
					value={email}
					onChange={handleEmailChange}
					error={!isValidEmail && email.length > 0}
					helperText={!isValidEmail && email.length > 0 ? 'Please enter a valid email address' : ''}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								{isValidEmail && (
									<Button
										variant="contained"
										color="primary"
										onClick={() =>
											addNewUser(email).then(() => {
												setEmail('');
												setIsValidEmail('');
											})
										}
									>
										ADD
									</Button>
								)}
							</InputAdornment>
						),
					}}
				/>
				<List dense sx={{ maxHeight: '50vh', overflowY: 'auto' }}>
					{users.map((user) => (
						<ListItem
							key={user.uid}
							sx={{
								display: 'flex',
								alignItems: 'center',
								paddingTop: 2,
								paddingBottom: 2,
								paddingLeft: 0,
								paddingRight: 0,
							}}
						>
							<Avatar sx={{ bgcolor: 'secondary.main', marginRight: 2 }} src={user.photoURL} />
							<Box sx={{ flexGrow: 1 }}>
								<Typography variant="subtitle1">{user.displayName}</Typography>
								<Typography variant="body2" color="text.secondary">
									{user.email}
								</Typography>
							</Box>
							{isAdmins ? (
								<FormControl size="small">
									<Select
										labelId="org-select-label"
										value={getRoleFromPrivilege(user.privilegeLevel)}
										onChange={(e) => updateOrDeleteUser(user, e.target.value)}
										sx={{
											fontSize: '14px',
										}}
										disabled={user.email === auth.currentUser.email}
									>
										{Object.keys(ROLE_PRIVILEGES).map((roleKey, index) => (
											<MenuItem
												key={roleKey + index}
												value={roleKey}
												sx={{
													fontSize: '14px',
												}}
											>
												{formatRoleName(roleKey)}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							) : (
								<Button onClick={() => updateOrDeleteUser(user)}>Remove</Button>
							)}
						</ListItem>
					))}
				</List>
			</Box>
		</Modal>
	);
}

export default UserAdminModalBase;
