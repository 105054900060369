import React, { useEffect, useState } from 'react';

import { ROLE_PRIVILEGES } from '../../../common/consts';
import UserAdminModalBase from '../UserAdminModalBase';
import { callFirebaseFunction } from '../../../utils/helpers';

function AdminsModal({ open, toggleModal }) {
	const [admins, setAdmins] = useState([]);
	const [loading, setLoading] = useState(false);
	const fetchAdmins = async () => {
		setLoading(true);
		try {
			const fetchedAdmins = await callFirebaseFunction('getAdmins');
			setAdmins(fetchedAdmins);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (admins.length === 0) {
			fetchAdmins();
		}
	}, []);

	const updateRole = async (user, role) => {
		setLoading(true);
		try {
			await callFirebaseFunction('updateUser', { email: user.email, privilegeLevel: ROLE_PRIVILEGES[role] });
			fetchAdmins();
		} catch {
			setLoading(false);
		}
	};

	const addNewAdmin = async (email) => {
		setLoading(true);
		try {
			await callFirebaseFunction('addAdmin', { email });
			fetchAdmins();
		} catch {
			setLoading(false);
		}
	};

	return (
		<UserAdminModalBase
			users={admins}
			open={open}
			toggleModal={toggleModal}
			updateOrDeleteUser={updateRole}
			type="admins"
			addNewUser={addNewAdmin}
			loading={loading}
		/>
	);
}

export default AdminsModal;
