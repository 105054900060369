import React, { useState } from 'react';
import DirectoryCard from '../DirectoryCard';
import { setDoc, collection, doc } from 'firebase/firestore';
import { notEmpty } from '../validators';
import { db } from '../../../firebase';

import './styles.css';

const NewFolderInput = ({ fetchDirectories, organizationId, hideNewFolderInput }) => {
	const [folderName, setFolderName] = useState('');
	const [isNameValid, setIsNameValid] = useState(true);

	const handleNameChange = (name) => {
		const isFolderNameValid = notEmpty(name);
		setFolderName(name);
		setIsNameValid(isFolderNameValid);
	};

	const onAddClick = async () => {
		const effectiveFolderName = !isNameValid || folderName.trim() === '' ? 'Untitled' : folderName.trim();

		const directory = {
			name: effectiveFolderName,
			organizationId,
			projects: [],
			subDirectories: [],
		};

		await addDirectory(directory);
		hideNewFolderInput();
	};

	// const updateDirectory = async (directory) => {
	// 	try {
	// 		await updateDoc(doc(db, 'directories', directory.id), directory);
	// 		fetchDirectories();
	// 	} catch (error) {
	// 		console.error('Error updating document: ', error);
	// 	}
	// };

	const addDirectory = async (directory) => {
		try {
			const directoriesCollectionRef = collection(db, "organizations", organizationId, "directories");

			await setDoc(doc(directoriesCollectionRef), directory);
			fetchDirectories();
		} catch (error) {
			console.error('Error adding document: ', error);
		}
	};

	return (
		<DirectoryCard
			directory={{ name: folderName }}
			inAddMode={true}
			onNameChange={handleNameChange}
			onSubmit={onAddClick}
		/>
	);
};

export default NewFolderInput;
